<template lang="pug">
  svg(width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M10.0871 12.4444C11.9132 12.4444 13.4785 10.9332 13.4785 8.97769C13.4785 7.11102 11.8263 5.95546 10.1741 5.15547C8.52188 4.44435 7.39143 2.4888 6.69576 0.622131C5.91314 2.66658 5.30443 3.73324 3.3044 5.15547C1.73916 6.22213 0 7.02213 0 8.8888C0 10.8444 1.47828 12.4444 3.39136 12.4444C4.69573 12.4444 5.73922 11.7332 6.34793 10.6666C6.43489 11.1999 6.43489 11.8221 6.26097 12.3555C5.73922 14.0444 3.21744 14.6666 3.21744 14.6666L2.95657 15.1999L10.435 15.111L10.1741 14.5777C10.1741 14.5777 7.30447 14.311 7.13055 12.3555C7.04359 11.8221 7.04359 11.1999 7.13055 10.6666C7.6523 11.7332 8.78275 12.4444 10.0871 12.4444Z')
</template>

<script>
export default {
  name: 'TopPokersSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>